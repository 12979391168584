<template>
  <HgNetmatchPackageTourSearch
    allowHotjarRecord
    :is-mobile="isMobile"
    :is-loading-destinations="$apollo.queries.netmatchAutosuggest.loading"
    :airports="allAirports"
    :searched-airports="searchAirports"
    :destinations="orderedDestinations"
    :ibe-tags="amadeusPackageTourCategories"
    :ibe-url="netmatchPackageTourSearchUrl"
    :translations="translations"
    @onChangeAirportTerm="changeAirportTerm"
    @onUpdateDestinationTerm="updateDestinationTerm"
    @onUpdateSearchParameters="updateSearchParameters"
    @onTriggerSearch="triggerSearch"
  />
</template>

<script>
import { mapState } from 'vuex';
import allAirportsQuery from '@/apollo/queries/airport/allAirports';
import autosuggestNetmatchQuery from '@/apollo/queries/destination/autosuggestNetmatch';
import autosuggestDefaultAmadeusQuery from '@/apollo/queries/destination/defaultDestinations';
import netmatchPackageTourSearchUrlQuery from '@/apollo/queries/netmatch/netmatchPackageTourSearchUrl';
import amadeusPackageTourCategoriesQuery from '@/apollo/queries/ibe/amadeusPackageTourCategories';
import { format } from 'date-fns';

import GtmEventsMixin from '@/mixins/GtmEventsMixin';

export default {
  mixins: [GtmEventsMixin],

  data: () => ({
    destinationTerm: '',
    orderTypes: ['Destination', 'City', 'Region', 'Poi', 'Accommodation'],
    orderedDestinations: [],
    netmatchAutosuggest: [],
    allAirports: [],
    searchAirports: [],
    airports: [],
    destination: null,
    dateRange: null,
    duration: null,
    travelers: { adults: 2 },
    hasParams: false,
    netmatchPackageTourSearchUrl: null,
    translations: {}
  }),

  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },

  watch: {
    netmatchAutosuggest() {
      if (this.netmatchAutosuggest && this.netmatchAutosuggest.length > 0) {
        // Create a Set of all types in the netmatchAutosuggest array
        const orderTypes = new Set(
          this.netmatchAutosuggest.map((elem) => elem.type)
        );

        // Create an Array from the Set and map over it. For each type, filter the netmatchAutosuggest array
        // Resulting in an array of arrays. Then flatten the array of arrays to a single array
        const ordered = Array.from(orderTypes).map((type) =>
          this.netmatchAutosuggest.filter((elem) => elem.type === type)
        );
        this.orderedDestinations = [].concat(...ordered);
      } else if (this.autosuggestDefaultAmadeus.length)
        // If no netmatchAutosuggest & default destinations are available, use default destinations
        this.orderedDestinations = this.autosuggestDefaultAmadeus;
    }
  },

  created() {
    this.translations = {
      ...this.$i18n.t('netmatchIBE'),
      destinationLabel: this.$i18n.t('netmatchIBE.packagetourLabel')
    };
  },

  mounted() {
    this.queryDefaultAirports();
    if (this.autosuggestDefaultAmadeus.length)
      // If default destinations are available, set them initial as ordered destinations
      this.orderedDestinations = this.autosuggestDefaultAmadeus;
  },

  methods: {
    updateDestinationTerm(term) {
      this.destinationTerm = term;
    },

    updateSearchParameters(params) {
      const {
        destination,
        airports,
        departureDate,
        duration,
        travelers,
        tags
      } = params;

      if (destination) {
        this.destination = destination;
        const propertiesToRemove = [
          '__typename',
          'villageName',
          'provinceName',
          'countryName',
          'destinationName',
          'default'
        ];
        if (this.destination.__typename)
          propertiesToRemove.forEach((prop) => delete this.destination[prop]);
      }

      if (travelers) this.travelers = travelers;

      this.airports = airports?.map((airport) => airport.iataCode) || [];
      this.tags = tags?.map((t) => t.id) || [];
      this.dateRange = departureDate;
      this.duration = duration || '';
      this.hasParams = true;
    },

    triggerSearch() {
      let clickedElement = 'none';
      const duration = this.duration;
      const destinationName = this.destination?.name || 'any';
      const period = this.dateRange
        ? format(this.dateRange, 'EE, dd.MM.yyyy')
        : '';

      if (destinationName !== 'any') {
        clickedElement = this.destination?.default ? 'dropdown' : 'autosuggest';
      }

      this.$gtm.push({
        event: 'IBE_search_used',
        clickedElement, // Dropdown, Autosuggest, None
        destination: destinationName, // Reiseziel bzw. Hotel
        period, // Reisezeitraum
        travelDuration: duration, // Reisedauer
        departureAirport: this.airports, // Da Mehrfachauswahl möglich: Mehrere Flughäfen am besten in einem Array ["FRA", "MUC"]
        travellers: this.travelers // Reisende
      });
    },

    changeAirportTerm(searchTerm) {
      this.queryAirports(searchTerm);
    },

    async queryDefaultAirports() {
      try {
        const {
          data: { allAirports }
        } = await this.$apollo.query({
          query: allAirportsQuery,
          variables: {
            filter: {
              onlyGrouped: true
            }
          }
        });
        this.allAirports = allAirports;
      } catch (e) {
        // eslint-disable-next-line
        console.error(
          'Apollo-error @NetmatchPackageTourSearch\n allAirportsQuery',
          e.graphQLErrors
        );
      }
    },

    async queryAirports(searchTerm) {
      try {
        const { data } = await this.$apollo.query({
          query: allAirportsQuery,
          variables: {
            limit: 500,
            filter: {
              name: searchTerm,
              onlyGrouped: true
            }
          }
        });
        this.searchAirports = data.allAirports;
      } catch (e) {
        // eslint-disable-next-line
        console.error(
          'Apollo-error @NetmatchPackageTourSearch \n allAirportsQuery',
          e.graphQLErrors
        );
      }
    }
  },

  apollo: {
    netmatchAutosuggest: {
      query: autosuggestNetmatchQuery,
      debounce: 300,
      skip() {
        return this.destinationTerm === '';
      },
      variables() {
        return {
          search: this.destinationTerm,
          productType: 'PACKAGE_TOUR'
        };
      },
      error(error) {
        // eslint-disable-next-line
        console.error(
          'Apollo-error @NetmatchPackageTourSearch \n autosuggestNetmatchQuery',
          error.graphQLErrors
        );
      }
    },
    amadeusPackageTourCategories: {
      query: amadeusPackageTourCategoriesQuery,
      error(error) {
        // eslint-disable-next-line
        console.error(
          'Apollo-error @NetmatchPackageTourSearch \n amadeusPackageTourCategoriesQuery',
          error.graphQLErrors
        );
      }
    },
    autosuggestDefaultAmadeus: {
      query: autosuggestDefaultAmadeusQuery,
      variables() {
        return {
          type: 'PACKAGE_TOUR'
        };
      },
      error(error) {
        // eslint-disable-next-line
        console.error(
          'Apollo-error @NetmatchPackageTourSearch \n autosuggestDefaultAmadeusQuery',
          error.graphQLErrors
        );
      }
    },
    netmatchPackageTourSearchUrl: {
      query: netmatchPackageTourSearchUrlQuery,
      skip() {
        return this.hasParams === false;
      },
      variables() {
        return {
          input: {
            destination: this.destination && {
              ...this.destination,
              type:
                this.destination.type == 'favoritesdefault'
                  ? 'Destination'
                  : this.destination.type
            },
            departureDate: this.$dateFns.addDays(this.dateRange, 1),
            duration: this.duration,
            airports: this.airports,
            numberOfAdults: this.travelers.adults,
            agesOfChildren: this.travelers.ages
          }
        };
      }
    }
  }
};
</script>
